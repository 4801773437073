import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Col, Container, Navbar, Row, Button, ListGroup } from "react-bootstrap"
import Layout from "../components/layout"
import TestList from "../components/testList"
import SEO from "../components/seo"
import ShareButtons from "../components/shareButtons"
import AdBanner from "../components/adBanner"
import Contact from "../components/contact"

export default function PageDetail({ pageContext, location, data }) {
  const page = data.markdownRemark
  const { hospitalData } = pageContext

  function fullTitle() {
    return (
      page.frontmatter.title +
      " medical check up package from " +
      page.frontmatter.location
    )
  }

  function seoTitle() {
    return (
      "Medical check up package - Compare price and test details | " +
      page.frontmatter.location
    )
  }

  const url = data.site.siteMetadata.siteUrl + location.pathname
  const twitterHandle = ""
  const tags = ""

  function loadOtherPackages(packages) {
    let loadedPackages = packages.filter(pkg => {
      return pkg.node.frontmatter.type !== "ads"
    })
    return loadedPackages
  }
  function loadAdvertisement(packages) {
    let loadedPackages = packages.filter(pkg => {
      return pkg.node.frontmatter.type === "ads"
    })
    return loadedPackages
  }

  function renderContact() {
    if (!hospitalData) return
    return (
      <Col xs={12}>
        <h4 className="mt-5">Contact:</h4>
        <hr className="mb-5" />
        <Contact contact={hospitalData.node.frontmatter.contact} />
      </Col>
    )
  }

  return (
    <Layout>
      <SEO title={seoTitle()} description={page.frontmatter.description} />
      <Container>
        <Row>
          <Col>
            <Row>
              <Col xs={12}>
                <h3>{fullTitle()}</h3>
                <Row>
                  <Col>
                    <p>{page.frontmatter.description}</p>
                  </Col>
                  <Col>
                    <h2 className="float-right">
                      {page.frontmatter.price === 0
                        ? "Free"
                        : page.frontmatter.price.toLocaleString("en-MY", {
                            style: "currency",
                            currency: "MYR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                    </h2>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="justify-content-center">
              <AdBanner />
            </Row>

            <Row>
              <Col xs={12}>
                <h4 className="mt-5">
                  This package is inclusive the following tests:
                </h4>
                <hr className="mb-5" />
                <TestList list={page.frontmatter.test_list || []} />
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <Col xs={12}>
                <div dangerouslySetInnerHTML={{ __html: page.html }} />
              </Col>
            </Row>
            <Row>{renderContact()}</Row>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col>
            <h4>Other packages from {page.frontmatter.location}</h4>
            <hr />
          </Col>
          <ListGroup as="ol" numbered className="mb-5">
            {loadAdvertisement(data.allMarkdownRemark.edges).map(({ node }) => (
              <ListGroup.Item
                as="li"
                key={node.id}
                className="d-flex justify-content-between align-items-start bg-warning"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <Link to={node.frontmatter.source_url}>
                      {node.frontmatter.title}
                    </Link>
                  </div>
                  {node.frontmatter.description}
                </div>
                <div className="d-flex justify-content-between">
                  <p className="h5 align-self-end">
                    {node.frontmatter.price === 0
                      ? "Free"
                      : node.frontmatter.price.toLocaleString("en-MY", {
                          style: "currency",
                          currency: "MYR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </p>
                </div>
              </ListGroup.Item>
            ))}
            {loadOtherPackages(data.allMarkdownRemark.edges).map(({ node }) => (
              <ListGroup.Item
                as="li"
                key={node.id}
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  </div>
                  {node.frontmatter.description}
                </div>
                <div className="d-flex justify-content-between">
                  <p className="h5 align-self-end">
                    {node.frontmatter.price === 0
                      ? "Free"
                      : node.frontmatter.price.toLocaleString("en-MY", {
                          style: "currency",
                          currency: "MYR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </p>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Row>
        <Row>
          <Col>
            <Navbar fixed="bottom" className="justify-content-center bg-dark">
              <h5 className="text-muted mr-sm-2">Share </h5>
              <div className="mr-auto">
                <ShareButtons
                  title={fullTitle()}
                  url={url}
                  twitterHandle={twitterHandle}
                  tags={tags}
                />
              </div>
              <Button variant="outline-primary" className="mr-sm-2" href="/">
                Home
              </Button>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $hospitalName: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        price
        description
        location
        source_url
        gender
        test_list
        date(formatString: "DD MMMM, YYYY")
      }
      excerpt
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          location: { in: [$hospitalName, "Shopee Online Store"] }
        }
        fields: { slug: { ne: $slug } }
      }
      sort: { order: ASC, fields: frontmatter___price }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            type
            title
            price
            source_url
            description
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
