import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStaticQuery, graphql } from "gatsby"

export default function TestList(props) {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { in: ["procedures"] } } }
        ) {
          edges {
            node {
              frontmatter {
                keyword
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  function renderTest(procedureName) {
    let procedure = allMarkdownRemark.edges.filter(prc => {
      return procedureName
        .toLowerCase()
        .includes(prc.node.frontmatter.keyword.toLowerCase())
    })
    if (procedure === "undefined" || procedure.length <= 0) {
      return procedureName
    }
    return <a href={procedure[0].node.fields.slug}>{procedureName}</a>
  }

  return (
    <ul class="list-group">
      {props.list.map(test => {
        return (
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <FontAwesomeIcon
              icon={"check"}
              size="lg"
              className="text-success"
            />
            {renderTest(test)}
          </li>
        )
      })}
    </ul>
  )
}
